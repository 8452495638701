<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">我的订单</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <van-pull-refresh
      v-model="isLoading"
      class="fixedStyle"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :offset="offset"
        @load="onLoad"
      >
        <div v-if="list && list.length">
          <!-- <div class="h85"></div> -->
          <div v-for="(item, i) in list" :key="i" class="order-item">
            <div class="order-item-head">
              <div>订单编号：{{ item.id }}</div>
              <div class="c2B67D9" :class="{'cFF0909': item.lastDay <= 5 }">{{item.lastDay > 0 ? item.lastDay + '天后到期' : '已到期'}}</div>
            </div>
            <div class="order-device">
              <img src="@/accesst/rent/order-sn.png" alt="" />
              <div>
                <div class="order-device-info">
                  <div class="t">设备租金：</div><div class="d">{{item.monthlyRent}}元/月</div>
                </div>
                <div class="order-device-info">
                  <div class="t">设备押金：</div><div class="d">{{item.deivceDeposit}}元</div>
                </div>
                <div class="order-device-info">
                  <div class="t">租赁时长：</div><div class="d">{{item.leaseMonths}}个月</div>
                </div>
                <div class="order-device-info">
                  <div class="t">赠送时长：</div><div class="d">{{item.leaseDaysGive}}天</div>
                </div>
                <div class="order-device-info">
                  <div class="t">订单状态：</div><div class="d">{{ item.statusName }}</div>
                </div>
              </div>
            </div>
            <div class="order-times">
              <div class="order-times-l">下单时间：{{ item.createTime | dateF }}</div>
              <div class="order-times-l">到期时间：{{  item.leaseEnd }}</div>
            </div>
            <div class="order-btns">
              <!-- 订单状态 为30 退回中 31 退款中 32 已关闭（已退款） 状态 不能更换或续租操作 剩余天数小于等于 5 大于零时给一个高亮 -->
              <!-- <div @click="xuzu(item)" class="order-b" :class="{'active': item.lastDay > 0 && item.lastDay <= 5, 'disabled': item.status == 31 || item.status == 32 }">续租</div> -->
              <div v-if="item.status == 30 || item.status == 31 || item.status == 32" class="order-b disabled">续租</div>
              <div v-else @click="xuzu(item)" class="order-b active" :class="{'active': item.lastDay > 0 && item.lastDay <= 5 }">续租</div>
              <div v-if="item.status == 30 || item.status == 31 || item.status == 32" class="order-b disabled">更换</div>
              <div v-else @click="change(item)" class="order-b">更换</div>
              <div @click="detail(item)" class="order-b">更多</div>
            </div>
          </div>
          <div class="h85"></div>
        </div>
        <!-- 无订单情况 -->
        <div @click="href()" v-else>
          <img class="no-order" src="@/accesst/rent/no-order.png" alt="" srcset="" />
          <div class="no-order-tip">您还没有租赁设备，点击按钮立即租赁</div>
        </div>
      </van-list>
    </van-pull-refresh>

    <div class="rentModel" v-show="show">
      <rentModel ref="rentModel" @close="show=false"/>
    </div>
  </div>  
</template>

<script>
import { List, PullRefresh, Empty, Icon } from 'vant';
import rentModel from './rentModel.vue';
import { rentConfig, orderNew, orderList } from '@/api/rent.js'
import moment from 'moment';
import { forEach } from 'lodash';

export default {
  components: {[List.name]: List, [Empty.name]: Empty, [PullRefresh.name]: PullRefresh, [Icon.name]: Icon, rentModel},
  data() {
    return {
      list: [],
      show: false,
      show: false,
      timer: null,
      scrollTop1: 0,
      isLoading: false,
      offset: 50,
      pageStart: 1,
      limit: 10,
      loading: false,
      finished: false,
    }
  },
  filters: {
    // 局部过滤器
    dateF: function(v) {
      if (!v) return '';
      return moment(v).format('yyyy-MM-DD HH:mm:ss')
    }
  },
  created() {
    // this.getList();
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,   // 禁止背景点击
      duration: 300,  //展示时间，0为持续展示
      overlay: true,  //是否显示背景遮罩层
    })
  },
  mounted() {
    this.getFaultIndicator();
    window.addEventListener("scroll", this.scrollTops, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollTops);
    this.end();
  },
  methods: {
    getList() {
      orderList({pageNo: 1, pageSize: 100}).then(res=> {
        // console.log('order-list===>',res)
        forEach(res.list, (v) => {
          v.lastDay = moment(v.leaseEnd).diff(moment(v.leaseStart), 'days');
          v.leaseEnd = moment(v.leaseEnd).format('yyyy-MM-DD');
          v.leaseStart = moment(v.leaseStart).format('yyyy-MM-DD');
        })
        console.log('order-list===>',res)
        this.list = res.list
      })
    },
    xuzu(item) {
      this.$refs.rentModel.initData(item);
      this.show = true;
    },
    href() {
      this.$router.push({ path: '/rentDevice' })
    },
    // 更换
    change(item) {
      this.$router.push({path: '/rent/change', query: { id: item.id, type: 'change' }});
    },
    // 详情
    detail(item) {
      this.$router.push({path: '/rent/detail', query: { id: item.id }});
    },
    start() {
      this.timer = setTimeout(() => {
        this.timer = null; // 清除计时器
        this.longPress(); // 触发长按事件
      }, 1000); // 设置长按时间为1秒
    },
    end() {
      if (this.timer) {
        clearTimeout(this.timer); // 清除计时器
      }
    },
    longPress() {
      document.querySelector(".fixedStyle").scrollTop = 0;
    },
    scrollTops() {
      this.scrollTop1 = document.querySelector(".fixedStyle").scrollTop;
    },
    disPlayBut() {
      if (document.querySelector(".fixedStyle").scrollTop !== 0) {
        if (document.querySelector(".fixedStyle").scrollTop < 50) {
          document.querySelector(".fixedStyle").scrollTop = 0;
        } else {
          document.querySelector(".fixedStyle").scrollTop -= 50;
        }
        setTimeout(() => {
          this.disPlayBut();
        }, 20);
      }
    },
    getFaultIndicator(res) {
      if (res == "search") {
        this.list = [];
        this.pageStart = 1;
        this.isLoading = false;
      }
      let params = {
        
        pageNo: this.pageStart,
        pageSize: this.limit,
      };

      // orderList({pageNo: 1, pageSize: 100}).then(res=> {
      //   // console.log('order-list===>',res)
      //   forEach(res.list, (v) => {
      //     v.lastDay = moment(v.leaseEnd).diff(moment(v.leaseStart), 'days');
      //     v.leaseEnd = moment(v.leaseEnd).format('yyyy-MM-DD');
      //     v.leaseStart = moment(v.leaseStart).format('yyyy-MM-DD');
      //   })
      //   console.log('order-list===>',res)
      //   this.list = res.list
      // })

      orderList(params).then(res=> {
        console.log(res, '---')
        if(res.list.length == this.limit) this.finished = false;
        else this.finished = true;
        forEach(res.list, (v) => {
          v.lastDay = moment(v.leaseEnd).diff(moment(v.leaseStart), 'days');
          v.leaseEnd = moment(v.leaseEnd).format('yyyy-MM-DD');
          v.leaseStart = moment(v.leaseStart).format('yyyy-MM-DD');
        })

        this.list = this.list.concat(res.list);
        this.loading = false;
      })
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.isLoading) {
        this.list = [];
        this.pageStart = 1;
        this.isLoading = false;
      } else {
        this.pageStart = this.pageStart + 1;
      }
      //初始化列表
      this.getFaultIndicator();
    }
  }
}
</script>


<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #F7F7F7;
  overflow: auto;
  .order-item {
    margin: .2rem auto;
    width: 6.78rem;
    padding: 0 .17rem;
    height: 5.63rem;
    background: #FFFFFF;
    border-radius: .08rem;
    .order-btns {
      padding-top: .24rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .order-b {
        width: 1.92rem;
        height: .77rem;
        line-height: .77rem;
        border-radius: .44rem;
        border: .02rem solid #0074FE;
        font-weight: 400;
        font-size: .27rem;
        text-align: center;
        color: #2B67D9;
      }
      .order-b.active {
        color: #FFF;
        border: unset;
        background: #2B67D9;
        box-shadow: 0px .04rem .15rem -.08rem rgba(57,79,154,0.3);
      }
      .order-b.disabled {
        border: .02px solid #ccc !important;
        color: #666 !important;
      }
    }
    .order-times {
      width: 6.75rem;
      height: 1.29rem;
      background: rgba(0,116,254,0.08);
      border-radius: .08rem;
      .order-times-l {
        padding:.19rem 0 0 .37rem;
        font-weight: 400;
        font-size: .27rem;
        color: #2B67D9;
      }
    }
    .order-device {
      padding: .23rem 0;
      display: flex;
      justify-content: flex-start;
      .order-device-info {
        display: flex;
        height: .4rem;
        font-weight: 400;
        font-size: .27rem;
        line-height: .38rem;
        text-align: left;
        .t {
          color: #666666;
        }
        .d {
          color: #010101;
        }
      }
      img {
        display: block;
        width: 2.67rem;
        height: 1.90rem;
        margin-right: .25rem;
        // background: linear-gradient( 180deg, #E3F6FF 0%, #D0FFF9 100%);
        border-radius: .08rem;
      }
    }
    .c2B67D9 {
      color: #2B67D9;
    }
    .cFF0909 {
      color: #FF0909
    }
    .order-item-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: .60rem;
      font-size: .23rem;
      color: #666666;
      border-bottom: 1px solid #EEE;
    }
  }
  .h85 {
    height: .85rem;
  }
  .no-order {
    display: block;
    margin: 2rem auto .2rem;
    width: 6.02rem;
    height: 3.37rem;
  }
  .no-order-tip {
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: .23rem;
    color: #999999;
  }
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      width: auto;
    }
  }
  .fixedStyle {
    position: fixed;
    top: .85rem;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
</style>  